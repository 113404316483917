import React from 'react';

export default props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 139.33 691.43"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#4e2c1d"
          d="M69.67,553s46.54-62,46.54-138.46H23.12C23.12,491,69.67,553,69.67,553"
        />
        <path
          fill="#4e2c1d"
          d="M69.67,0S0,92.79,0,207.25,69.67,414.5,69.67,414.5s69.66-92.79,69.66-207.25S69.67,0,69.67,0"
        />
        <path
          fill="#4e2c1d"
          d="M69.67,553a1385,1385,0,0,0-7,138.47H76.62c0-76.48-7-138.47-7-138.47"
        />
      </g>
    </g>
  </svg>
);
