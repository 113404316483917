import '../styles/main.scss';

import React, { useContext } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Cart from '../components/Cart';
import Context from '../context/Context';
import Analytics from '../components/Analytics';
import NewsletterModal from '../components/NewsletterModal';


import {
  LazyMotion,
  domAnimation,
  m as motion,
  AnimatePresence,
} from 'framer-motion';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease`,
  },
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

const Layout = ({ pageContext, children, location }) => {
  const { header, footer } = pageContext;

  const resetScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <LazyMotion features={domAnimation} strict>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: TRANSITION_DURATION / 1000 }}
      >
        <Header location={location} {...header} />
        <Analytics
          // facebookPixelId={process.env.GATSBY_FB_PIXEL_ID}
          klaviyoId={process.env.GATSBY_KLAVIYO_COMPANY_ID}
        />
        <Cart />
        <NewsletterModal klaviyoListId={process.env.GATSBY_KLAVIYO_LIST_ID} />
        <AnimatePresence mode="wait" onExitComplete={resetScroll}>
          <motion.div
            key={
              location.pathname.includes('account')
                ? location.pathname.split('account')[0]
                : location.pathname
            }
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: TRANSITION_DURATION / 1000 }}
            className="bg-warm z-2"
          >
            {children}
          </motion.div>
        </AnimatePresence>
        <Footer {...footer} />
      </motion.div>
    </LazyMotion>
  );
};

export default Layout;
