import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Context from '../context/Context';
import Close from './icons/Close';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

const Cart = () => {
  const [cartNote, setCartNote] = useState('Enter your gift note ');
  const [hasSavedNote, setHasSavedNote] = useState(false);
  const {
    checkout,
    updateQuantityInCart,
    isCartOpen,
    setCartOpen,
    setOrderGiftStatus,
  } = useContext(Context);

  const { lineItems = [], customAttributes = [] } = checkout;

  const goToCheckout = () => {
    const checkoutURL = checkout.webUrl;
    window.open(checkoutURL, '_self');
  };

  const closeCart = () => setCartOpen(false);

  const cartRef = useRef(null);

  useEffect(() => {
    if (cartRef.current) {
      if (isCartOpen) {
        disableBodyScroll(cartRef.current);
      } else {
        enableBodyScroll(cartRef.current);
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isCartOpen]);

  return (
    <React.Fragment>
      <div
        ref={cartRef}
        className={classNames('cart__underlay pf fill top bottom left right', {
          open: isCartOpen,
        })}
        onClick={closeCart}
      />
      <aside
        className={classNames('cart__modal pf bl--dark-brown', {
          open: isCartOpen,
        })}
      >
        <header className="cart__header df aic jcb p2">
          <p className="uppercase">Shopping Cart</p>
          <button className="cart__close" onClick={closeCart}>
            <Close />
          </button>
        </header>
        {lineItems && lineItems.length > 0 ? (
          <React.Fragment>
            <section className="cart__content df fdc p2 ofy--scroll">
              {lineItems.map((item, index) => {
                return (
                  <article
                    className="cart__product df b--dark-brown mb2"
                    key={index}
                  >
                    <section className="cart__product-image-wrapper br--dark-brown">
                      <picture className="cart__product-image db image">
                        <source
                          srcSet={`${item.variant.image.src}?w=800&auto=format&q=100`}
                          media="(min-width: 1000px)"
                        />
                        <source
                          srcSet={`${item.variant.image.src}?w=600&auto=format`}
                          media="(min-width: 600px)"
                        />
                        <img
                          alt={item.title}
                          src={`${item.variant.image.src}?w=600&auto=format`}
                        />
                      </picture>
                    </section>
                    <section className="cart__product-desc df fdc">
                      <div className="p1">
                        <p className="fw--800">{item.title}</p>
                        {item.vendor && <p>by {item.vendor}</p>}
                        {item.variant.selectedOptions[0].name.toLowerCase() !==
                          'title' && (
                          <p className="pt1">
                            <span className="dib mono--sm uppercase mr1 ">
                              {item.variant.selectedOptions[0].name}
                            </span>
                            <span className="dib">{item.variant.title}</span>
                          </p>
                        )}
                      </div>
                      <div className="bt--dark-brown df cart__product-controls">
                        <div className="br--dark-brown cart__quantity p1">
                          <p className="mono--sm pb1 uppercase">QTY</p>
                          <div className="df aie jcb">
                            <p>{item.quantity}</p>
                            <div className="cart__quantity-buttons df aic">
                              <button
                                className="tl pr1"
                                onClick={() =>
                                  updateQuantityInCart(
                                    item.id,
                                    item.quantity - 1
                                  )
                                }
                              >
                                –
                              </button>{' '}
                              <button
                                className="tl"
                                onClick={() =>
                                  updateQuantityInCart(
                                    item.id,
                                    item.quantity + 1
                                  )
                                }
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="cart__price p1">
                          <p className="mono--sm pb1 uppercase">Price</p>
                          <p>${item.variant.price.amount}</p>
                        </div>
                      </div>
                    </section>
                  </article>
                );
              })}
            </section>
            <footer className="cart__footer">
              <div className="p-20">
                <label className="flex gap-10">
                  <input
                    type="checkbox"
                    id="gift"
                    name="gift"
                    onChange={e =>
                      setOrderGiftStatus({ giftWrap: e.target.checked })
                    }
                    checked={
                      customAttributes.find(({ key }) => key === 'Gift Wrap')
                        ?.value === 'Yes'
                    }
                  />
                  <span className="sans--sm">Is this a gift?</span>
                </label>
                <label className="block mt-10">
                  <textarea
                    disabled={
                      customAttributes.find(({ key }) => key === 'Gift Wrap')
                        ?.value !== 'Yes'
                    }
                    className={classNames(
                      'p-10 border border-solid sans--sm bg-transparent w-full placeholder:text-black',
                      {
                        'opacity-50 cursor-not-allowed':
                          customAttributes.find(
                            ({ key }) => key === 'Gift Wrap'
                          )?.value !== 'Yes',
                      }
                    )}
                    placeholder="Add an (optional) note to your order"
                    onChange={e => {
                      setHasSavedNote(false);
                      setCartNote(e.target.value);
                    }}
                    defaultValue={cartNote}
                  />
                </label>
                <button
                  disabled={cartNote === ''}
                  onClick={() => {
                    setOrderGiftStatus({
                      giftWrap:
                        customAttributes.find(({ key }) => key === 'Gift Wrap')
                          ?.value === 'Yes',
                      giftMessage: cartNote,
                    });
                    setHasSavedNote(true);
                  }}
                >
                  {hasSavedNote ? 'Saved!' : 'Save Note'}
                </button>
              </div>
              <div className="cart__cloverly bt--dark-brown sans--sm" />
              <div className="cart__subtotal p1 pl2 pr2 sans--sm bt--dark-brown x">
                Subtotal {checkout.subtotalPrice.amount} USD
                <small className="db">
                  (Taxes will be calculated at checkout)
                </small>{' '}
              </div>
              <button
                className="product-tile__add-to-cart pt1 pb1 pl2 pr2 sans--md bt--dark-brown x tl fw--800 uppercase"
                onClick={goToCheckout}
              >
                Proceed to checkout
              </button>
            </footer>
          </React.Fragment>
        ) : (
          <section className="cart__empy pt5 p2">
            <p className="cart__empty--title sans--md mb2">
              Your cart is currently empty.
            </p>

            <Link
              className="cart__empty--link link--underline"
              to="/"
              onClick={closeCart}
            >
              Continue Shopping.
            </Link>
          </section>
        )}
      </aside>
    </React.Fragment>
  );
};

export default Cart;
