exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-wishlists-[id]-jsx": () => import("./../../../src/pages/wishlists/[id].jsx" /* webpackChunkName: "component---src-pages-wishlists-[id]-jsx" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-account-jsx": () => import("./../../../src/templates/Account.jsx" /* webpackChunkName: "component---src-templates-account-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../../../src/templates/Collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-designers-page-jsx": () => import("./../../../src/templates/DesignersPage.jsx" /* webpackChunkName: "component---src-templates-designers-page-jsx" */),
  "component---src-templates-featured-designer-jsx": () => import("./../../../src/templates/FeaturedDesigner.jsx" /* webpackChunkName: "component---src-templates-featured-designer-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-preview-jsx": () => import("./../../../src/templates/Preview.jsx" /* webpackChunkName: "component---src-templates-preview-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/Search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-spotlight-jsx": () => import("./../../../src/templates/Spotlight.jsx" /* webpackChunkName: "component---src-templates-spotlight-jsx" */),
  "component---src-templates-spotlight-list-jsx": () => import("./../../../src/templates/SpotlightList.jsx" /* webpackChunkName: "component---src-templates-spotlight-list-jsx" */)
}

