// Polyfills


const React = require('react');
const Layout = require('./src/layout/Layout.jsx').default;
const { wrapRootElement } = require('./wrapRootElement');
const { Initialize } = require('./src/components/Analytics');

// const analytics = Initialize({
//   // Setup analytics
//   facebookPixelId: process.env.GATSBY_FB_PIXEL_ID,
//   googleAnalyticsPropertyId: process.env.GATSBY_GA_ID,
//   googleLinkerDomains: ['eugeniedetroit.com', 'eugenie-detroit.myshopify.com/'],
// });

// window.analytics = analytics;

// Wrap every page with the main layout
exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

exports.wrapRootElement = wrapRootElement;
