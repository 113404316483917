import React, { useState, useContext, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Context from '../context/Context';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import { Link, navigate } from 'gatsby';
import Menu from './icons/Menu';
import Close from './icons/Close';
import ArrowRight from './icons/ArrowRight';
import Search from './icons/Search';
import ShoppingBag from './icons/ShoppingBag';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import LogoBrown from './icons/LogoBrown';

const Header = ({
  enableBanner,
  banner,
  desktopMenu = [],
  mobileMenu = [],
}) => {
  const mobileMenuRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCollectionsHovered, setIsCollectionsHovered] = useState(false);

  const {
    checkout,
    isSearchOpen,
    isCartOpen,
    setSearchOpen,
    setCartOpen,
    searchStore,
  } = useContext(Context);

  const { lineItems = [] } = checkout;

  const cartCount =
    lineItems.length > 0 &&
    reduce(
      lineItems,
      (total, item) => {
        return total + item.quantity;
      },
      0
    );

  const handleSearch = event => {
    event.preventDefault();
    const searchTerm = event.target.search.value;
    searchStore(searchTerm);
    setSearchOpen(false);
    navigate(`/search?q=${searchTerm}`, {
      replace: true,
    });
  };

  const bannerTicker = enableBanner && banner && (
    <div className="of--hidden">
      <ul className="banner banner--header bb--dark-brown ws--nowrap list list--horizontal gutter--none list--forward bg--tan">
        {Array.apply(null, Array(20)).map((item, index) => (
          <li key={index} className="list__item rich-text serif--sm">
            <div className="banner__item pl2 pr2">
              <BlockContent blocks={banner} serializers={richText} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  const collectionsDropDown = find(
    desktopMenu,
    link => !!link.dropdownLinks
  ) && (
    <div className="pr x site-header__desktop-collections-wrapper">
      <nav
        onMouseLeave={() => setIsCollectionsHovered(false)}
        className={classNames(
          'site-header__desktop-collections pa df aic bb--dark-brown x tc show--lg',
          {
            visible: isCollectionsHovered,
          }
        )}
      >
        {find(desktopMenu, link => !!link.dropdownLinks).dropdownLinks.map(
          link => (
            <Link
              key={link._key}
              to={`/collections/${link.slug}`}
              onClick={() => {
                setIsCollectionsHovered(false);
                navigate(`/collections/${link.slug}`);
              }}
              className="df aic mr3 sans--sm uppercase site-header__desktop-collection-link"
            >
              <div className="circle circle--outline mr1" />
              {link.title || link.linkTitle}
            </Link>
          )
        )}
      </nav>
    </div>
  );

  const searchForm = (
    <div className="pr x">
      <form
        onSubmit={handleSearch}
        className={classNames(
          'search-form bl--dark-brown--lg bb--dark-brown pa df jcb pr1',
          {
            visible: isSearchOpen,
          }
        )}
      >
        <label className="x">
          <input
            type="text"
            name="search"
            className="search-form__input pl2 pl1--lg sans--sm"
            placeholder="Seach here"
          />
        </label>
        <button className="search-form__submit" type="submit">
          <ArrowRight />
        </button>
      </form>
    </div>
  );

  return (
    <React.Fragment>
      {bannerTicker}
      <header className="site-header psy ">
        <div className="df jcb aic fw">
          <section className="site-header__main-nav bb--dark-brown x df jcb aic">
            <Link
              onClick={() => {
                setTimeout(() => {
                  setMenuOpen(false);
                }, 300);
              }}
              to="/"
              className="site-header__logo ml2"
            >
              <LogoBrown />
            </Link>

            {desktopMenu && desktopMenu.length > 0 && (
              <nav className="show--lg site-header__desktop-nav df aic">
                {desktopMenu.map(link => {
                  if (!!link.dropdownLinks) {
                    return (
                      <div
                        key={link._key}
                        onMouseEnter={() => setIsCollectionsHovered(true)}
                        className="site-header__desktop-collection-trigger y"
                      >
                        <Link
                          to={`/collections/new-arrivals`}
                          onClick={() => {
                            setIsCollectionsHovered(false);
                            navigate(`/collections/new-arrivals`);
                          }}
                          className="df aic mr3 sans--sm y"
                        >
                          <div
                            className="circle mr1"
                            style={{ backgroundColor: 'currentcolor' }}
                          />
                          <p>{link.title}</p>
                        </Link>
                      </div>
                    );
                  } else {
                    return (
                      <Link
                        key={link._key}
                        to={link.destination}
                        className="df aic mr3 sans--sm"
                      >
                        <div
                          className="circle mr1"
                          style={{ backgroundColor: link.color }}
                        />
                        {link.title}
                      </Link>
                    );
                  }
                })}
              </nav>
            )}
            <aside className="show--lg site-header__desktop-menu">
              <Link
                to="/account"
                className="bl--dark-brown show--lg h-full uppercase leading-[50px] inline-block px-20 transition-colors duration-300 hover:bg-dark-brown hover:text-warm"
              >
                Account
              </Link>

              <button
                className="button--header bl--dark-brown show--lg"
                aria-label="Open Search Button"
                onClick={() => setSearchOpen(!isSearchOpen)}
              >
                Search
              </button>

              <button
                className="button--header bl--dark-brown"
                aria-label="Open Search Button"
                onClick={() => setCartOpen(!isCartOpen)}
              >
                Cart ({cartCount ? cartCount : 0})
              </button>
            </aside>
            <aside className="hide--lg df aic">
              <button
                className="mobile-cart mr1 "
                onClick={() => setSearchOpen(!isSearchOpen)}
              >
                <Search />
              </button>

              <button
                onClick={() => setCartOpen(!isCartOpen)}
                className="mobile-cart mr1 pr"
              >
                <ShoppingBag className="mobile-cart-img pa tc x" />
              </button>
              <button
                onClick={() => setMenuOpen(!isMenuOpen)}
                className="site-header__menu hide--lg mr2"
              >
                {isMenuOpen ? <Close /> : <Menu />}
              </button>
            </aside>
          </section>
          {collectionsDropDown}
          {searchForm}
        </div>
        <div className="pr">
          <aside
            ref={mobileMenuRef}
            className={classNames('mobile-menu hide--lg p2', {
              active: isMenuOpen,
            })}
          >
            {mobileMenu &&
              mobileMenu.map(link => {
                if (!!link.dropdownLinks) {
                  return (
                    <div key={link._key} className="sans--lg mb1">
                      <p className="sans--lg mb1">Shop</p>
                      {link.dropdownLinks.map(link => {
                        return (
                          <Link
                            key={link._key}
                            to={`/collections/${link.slug}`}
                            onClick={() => {
                              setTimeout(() => {
                                setMenuOpen(false);
                              }, 300);
                            }}
                            className="mobile-menu__link sans--lg db mb1 ml2"
                          >
                            {link.linkTitle}
                          </Link>
                        );
                      })}
                    </div>
                  );
                } else {
                  return (
                    <Link
                      key={link._key}
                      to={link.url}
                      onClick={() => {
                        setTimeout(() => {
                          setMenuOpen(false);
                        }, 300);
                      }}
                      className="mobile-menu__link sans--lg db mb1"
                    >
                      {link.title}
                    </Link>
                  );
                }
              })}
          </aside>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
