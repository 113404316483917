import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 687.15 180.53"
    {...props}
  >
    <title>Eugenie Logo</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#4e2c1d"
          d="M16.69,82.22H77.46q-1.59-12.1-9.74-19.66T47.47,55a29.61,29.61,0,0,0-20.26,7.35q-8.34,7.35-10.52,19.86m31,58.59q-20.67,0-34.16-13.61T0,91.36Q0,69.12,13.41,55.41t33.86-13.7q20.25,0,33.07,13.11T93.15,89.57a37.32,37.32,0,0,1-.4,5.16H15.89q.8,15.09,9.53,24t22.25,8.93a31.38,31.38,0,0,0,17.87-5.06,22,22,0,0,0,9.54-13.81l14.1,4.57a33.87,33.87,0,0,1-15,20.16q-11.42,7.24-26.51,7.25"
        />
        <path
          fill="#4e2c1d"
          d="M178.75,43.69h15.88v95.13H178.75V126.11q-9.75,14.7-29.2,14.7-15.88,0-26.42-9.83t-10.52-27.91V43.69H128.5v58.39q0,11.72,6.85,18t18.37,6.26q11.33,0,18.17-7.84t6.86-21.35Z"
        />
        <path
          fill="#4e2c1d"
          d="M348.54,82.22h60.78q-1.6-12.1-9.74-19.66T379.32,55a29.61,29.61,0,0,0-20.25,7.35q-8.34,7.35-10.53,19.86m31,58.59q-20.67,0-34.16-13.61T331.86,91.36q0-22.24,13.4-35.95t33.87-13.7q20.25,0,33.06,13.11T425,89.57a35.83,35.83,0,0,1-.4,5.16H347.75q.79,15.09,9.53,24t22.25,8.93a31.32,31.32,0,0,0,17.86-5.06,22,22,0,0,0,9.54-13.81L421,113.4a33.81,33.81,0,0,1-15,20.16q-11.43,7.24-26.51,7.25"
        />
        <path
          fill="#4e2c1d"
          d="M490.35,41.71q16.68,0,26.8,9.53t10.13,26.81v60.77H511.4V81.62q0-12.51-6.76-19T486.37,56.2q-11.31,0-18.27,7.85t-7,21.35v53.42H445.26V43.69h15.89V56.6q9.53-14.9,29.2-14.89"
        />
        <path
          fill="#4e2c1d"
          d="M555.47,43.69h15.9v95.13h-15.9ZM572,20.26a12,12,0,0,1-8.54,3.38A11.68,11.68,0,0,1,555,20.26a11.16,11.16,0,0,1-3.48-8.34A11.49,11.49,0,0,1,555,3.48,11.49,11.49,0,0,1,563.42,0,11.83,11.83,0,0,1,572,3.48a11.31,11.31,0,0,1,3.57,8.44A11,11,0,0,1,572,20.26"
        />
        <path
          fill="#4e2c1d"
          d="M610.69,82.22h60.77q-1.59-12.1-9.74-19.66T641.47,55a29.61,29.61,0,0,0-20.26,7.35q-8.34,7.35-10.52,19.86m31,58.59q-20.67,0-34.16-13.61T594,91.36q0-22.24,13.41-35.95t33.86-13.7q20.25,0,33.07,13.11t12.81,34.75a37.32,37.32,0,0,1-.4,5.16H609.89q.79,15.09,9.53,24t22.25,8.93a31.38,31.38,0,0,0,17.87-5.06,22,22,0,0,0,9.54-13.81l14.1,4.57a33.87,33.87,0,0,1-15,20.16q-11.41,7.24-26.51,7.25"
        />
        <path
          fill="#4e2c1d"
          d="M286.48,116.88q-9,9.83-23.13,9.83T239.81,117q-9.44-9.74-9.44-25.62t9.44-25.72a31.28,31.28,0,0,1,23.54-9.83q14.08,0,23.13,9.93t9,25.62q0,15.69-9,25.52m9-73.19V60q-11.32-18.27-35-18.27-20.06,0-33.26,13.9T214.09,91.36q0,21.84,13.31,35.65t33.16,13.8q23.63,0,35-18.27v12.51q0,15.09-8.54,23.23t-22.64,8.15q-11.53,0-19.76-5.57a23.91,23.91,0,0,1-10.43-14.29l-14.3,6.16q4,12.69,16.19,20.25t28.49,7.55q20.87,0,33.87-12.32t13-34.15V43.69Z"
        />
      </g>
    </g>
  </svg>
);
